<template>
  <div style="padding: 48px 0 56px 0; width: 1200px; margin: 0 auto">
    <p
      class="tc"
      style="
        margin: 0 auto;
        margin-bottom: 24px;
        width: 200px;
        height: 64px;
        line-height: 64px;
        background: #007bc7;
        font-size: 32px;
        font-weight: 800;
        color: #fff;
        border-radius: 16px;
      "
    >
      查询结果
    </p>
    <div
      class="clearfix whiteBgBox"
      style="padding: 0 24px; height: 56px; line-height: 56px"
    >
      <p class="fl" style="font-size: 18px; font-weight: 800; color: #333">
        {{ $route.query.key }}（<span>{{ totalCount }}</span
        >）
      </p>
      <div class="fr searchItem">
        <el-select
          placeholder="请选择"
          clearable
          @change="getList(1)"
          v-model="search.state"
          ><el-option value="1" label="有效"></el-option
          ><el-option value="2" label="失效"></el-option
        ></el-select>
        <el-input
          @clear="getList(1)"
          suffix-icon="el-icon-search"
          clearable
          @blur="getList(1)"
          @keyup.enter.native="getList(1)"
          placeholder="请输入产品名称搜索"
          v-model="search.productName"
        ></el-input>
      </div>
    </div>
    <div
      class="whiteBgBox"
      v-loading="$store.state.tableLoading"
      style="padding: 10px 24px; margin-top: 8px"
    >
      <el-table
        border
        highlight-current-row
        style="width: 100%"
        :data="tableData"
      >
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column label="防爆编号" prop="numbers"></el-table-column>
        <el-table-column label="产品名称" prop="productName"></el-table-column>
        <el-table-column
          label="产品型号"
          prop="productNumber"
        ></el-table-column>
        <el-table-column
          label="防爆认证标志"
          prop="certificationMark"
        ></el-table-column>
        <el-table-column label="状态" prop="state">
          <template slot-scope="scope">
            <span
              :style="{
                color: scope.row.state === '有效' ? '#36C626' : '#FF6F77',
              }"
              >{{ scope.row.state }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="openDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <div class="pagePosition tc" style="margin-top: 20px">
          <el-pagination
            background
            layout="prev, pager, next"
            prev-text="<"
            next-text=">"
            :total="totalCount"
            :page-size="25"
            :current-page.sync="pageNumber"
            @current-change="pageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Dialog
      ref="visible"
      :isValiable="isValiable"
      :formData="formData"
    ></Dialog>
  </div>
</template>

<script>
import Dialog from "./detail.vue";
export default {
  name: "Home",
  components: {
    Dialog,
  },
  data() {
    return {
      tableData: [],
      totalCount: 0,
      pageNumber: 1,
      isValiable: false,
      search: {
        productName: "",
        state: "",
      },
      formData: {},
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(p) {
      let param = this.search;
      param.type = "company";
      param.companyName = this.$route.query.key;
      param.page = p ? p : this.pageNumber;
      param.limit = 25;
      this.$store.state.tableLoading = true;
      this.$fetch(
        this.$requestUrl + "qingzhi-admin/web/queryByName",
        param
      ).then((res) => {
        this.$store.state.tableLoading = false;
        if (res.code === 500) {
          this.$message.error(res.msg);
          return;
        }
        this.tableData = res.explosion ? res.explosion.list : [];
        this.totalCount = res.explosion ? res.explosion.totalCount : 0;
      });
    },
    openDetail(row) {
      this.$fetch(this.$requestUrl + "qingzhi-admin/web/view", {
        id: row.id,
      }).then((res) => {
        if (res.code === 500) {
          this.$message.error(res.msg);
          return;
        }
        this.formData = res.explosion;
        this.isValiable = res.explosion.state === "有效";
        this.$refs.visible.visible = true;
      });
    },
    pageChange(p) {
      this.pageNumber = p;
      this.getList(p);
    },
  },
};
</script>
